import { useState, useRef } from 'react';
import { daysBetweenDates, reminderTagClassName, getCardTagText, isCardTagHidden, formatDay } from '../../../../utils';
import moment from 'moment';
import { AlwaysVisibleCardTag, AlertTimeIcon } from '../../../../components';
import { connect } from "react-redux";

function DesktopCalendarCard(props) {
  const {
    schedule
  } = props;

  const { completed, reminder_in_day, due_date } = schedule?.raw

  const dateTimeToday = new Date();
  const dateToday = new Date();
  const dueDate = new Date(due_date);
  const past_due = due_date ? dateTimeToday > new Date(due_date) :  false;

  dueDate?.setHours(0,0,0,0);
  dateToday?.setHours(0,0,0,0);

  const diff_in_day = (past_due || due_date === undefined) ? 0 : daysBetweenDates(dateToday, dueDate);

  const isHidden = () => {
    return isCardTagHidden(completed, reminder_in_day, due_date, diff_in_day)
  }

  return (
    <div className="d-flex justify-content-between mb-1 position-relative">
      <div className={`desktop-calendar_card-info`}>
        {
          !isHidden() &&
          <AlwaysVisibleCardTag
            completed={completed}
            due_date={due_date}
            reminder_in_day={reminder_in_day}
            diff_in_day={diff_in_day}
            past_due={past_due}
            className={'mb-1'}
          />
        }
        <div className={`mb-1 content-label__text ${ isHidden() ? 'tagged' : '' }`}>{schedule.title}</div>
      </div>
      <div className="desktop-calendar_card-dueDate">
        {
          formatDay(due_date, 'hh:mm a')
        }
      </div>
    </div>
  );
}

export default DesktopCalendarCard;

import { useState, useRef } from 'react';
import { AlertTimeIcon } from '../';
import { daysBetweenDates, reminderTagClassName, getCardTagText } from '../../utils';
import moment from 'moment';

function AlwaysVisibleCardTag(props) {
  const {
    completed,
    reminder_in_day,
    due_date,
    diff_in_day,
    className,
    past_due
  } = props;

  return (
    <div className={`tag ${className} ${reminderTagClassName(past_due ? -1 : diff_in_day, completed)}`}>
      <div className="d-flex align-items-center h-100">
        <AlertTimeIcon />
        <span>{getCardTagText(past_due ? -1 : diff_in_day, completed)}</span>
      </div>
    </div>
  );
}

export { AlwaysVisibleCardTag };

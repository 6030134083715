import { useState, useRef } from 'react';
import { AlertTimeIcon } from '../';
import { daysBetweenDates, reminderTagClassName, getCardTagText, isCardTagHidden } from '../../utils';
import moment from 'moment';

function tagSizeClass(size) {
  if(size === 'lg'){
    return 'tag-large'
  }

  return ''
}

function CardTag(props) {
  const {
    completed,
    reminder_in_day,
    due_date,
    useVisibility,
    className,
    size
  } = props;

  const dateTimeToday = new Date();
  const dateToday = new Date();
  const dueDate = new Date(due_date);
  const past_due = due_date ? dateTimeToday > new Date(due_date) :  false;

  dueDate?.setHours(0,0,0,0);
  dateToday?.setHours(0,0,0,0);

  const diff_in_day = (past_due || due_date === undefined) ? 0 : daysBetweenDates(dateToday, dueDate);

  const isHidden = () => {
      return isCardTagHidden(completed, reminder_in_day, due_date, diff_in_day)
  }

  const style = () => {
    if(useVisibility === undefined) return {};

    return { visibility: isHidden() ? 'hidden' : 'visible'}
  }

  if(isHidden() && useVisibility === undefined) return null;

  return (
    <div className={`tag ${className} ${tagSizeClass(size)} ${reminderTagClassName(past_due ? -1 : diff_in_day, completed)}`}
         style={style()}>
         <div className="d-flex align-items-center h-100">
           <AlertTimeIcon />
           <span>{getCardTagText(past_due ? -1 : diff_in_day, completed)}</span>
         </div>
    </div>
  );
}

export { CardTag };

export const isCardTagHidden = (completed, reminder_in_day, due_date, diff_in_day) => {
    if(completed){
      return false;
    }
    else if((completed === false || completed === undefined ) && (reminder_in_day === undefined || due_date === undefined || due_date === null || reminder_in_day === null || reminder_in_day === 0 )){
      return true;
    } else if(diff_in_day > reminder_in_day) {
      return true;
    } else {
      return false;
    }
}

import React, { useEffect, useState } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import WorkSpaceForm from "../WorkSpacePage/form";
import { work_spaceActions } from '../../../actions';
import { buildFormDataFromObject } from '../../../utils/commonUtils';
import { useParams} from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import { Popup, LoadSpinner } from '../../../components';

function WorkSpaceEdit(props) {
  const dispatch = useDispatch();
  const { initialData, handleCloseEditForm, work_space_error, workspace_id, workspace_loading } = props;

  useEffect(() => {
  }, []);

  const deleteWorkSpace = (id) => {
    dispatch(work_spaceActions.destroyWorkSpace(id));
  }

  /*
    Popup Modal
  */
  const [popup, setPopUp] = useState({
    show: false,
    id: null,
  });;

  const handleClose = () => {
    setPopUp({show: false, id: null});
  }

  const handleShow = (id) => setPopUp({show: true, id});

  const handleDelete = () => {
    deleteWorkSpace(popup.id);
    handleClose();
  }

  const showResults = (values) => {
    if(workspace_loading) return;

    let formData = new FormData();
    buildFormDataFromObject(formData, values, "workspace");

    dispatch(work_spaceActions.updateWorkSpace(formData, workspace_id, handleCloseEditForm));
  }

  if (props.loading) {
    return <LoadSpinner />
  }

  return (
    <WorkSpaceForm onSubmit={showResults}
      initialData={initialData}
      work_space_error={work_space_error && work_space_error}
      handleCloseForm= {handleCloseEditForm}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.work_space.loading,
    workspace_loading: state.work_space.workspace_loading,
    error: state.work_space.error,
    editWorkSpace: state.work_space.editWorkSpace,
    initialData: state.work_space.initialData
  };
};

export default connect(mapStateToProps, { work_spaceActions })(WorkSpaceEdit);

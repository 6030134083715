import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { work_spaceActions } from '../../../actions';
import { validation } from '../../../shared/form-validations';
import { textInput, selectField, fileUpload } from '../../../shared/form-elements'
import { reduxForm, Field, reset, change } from "redux-form";
import { Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { adminBaseURL } from '../../../utils/commonUtils';
import CloseButton from 'react-bootstrap/CloseButton';
import CheckIcon from "../../../images/icons/white/check.svg";

function WorkSpaceForm(props) {
  const {
    handleSubmit,
    editWorkSpace,
    error,
    initialData,
    newForm,
    handleCloseForm,
    invalid,
    workspace_loading
  } = props;
  const dispatch = useDispatch();
  let { workspace_id } = useParams();
  const workspaceBaseUrl = adminBaseURL("workspaces")

  // const id = useSelector(state => {
  //  if(state.work_space.editWorkSpace) {
  //      return state.work_space.editWorkSpace.data.id
  //  }
  //  return 0;
  // });

  useEffect(() => {
    if(newForm === "newForm") {
      dispatch(change("work_spaceform", "name", ''))
      dispatch(change("work_spaceform", "logo", ''))
      dispatch(change("work_spaceform", "duplicate_from_id", ''))
    }
  }, []);

  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <Modal
      show={true}
      onHide={handleCloseForm}
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          { newForm == "newForm" ? "Add Workspace" : "Edit Workspace" }
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          {error?.map((data, index) => (
            <div className="error_message" key={index}>{data}</div>
          ))}
          <div className="col-lg-12">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <Field name="name" type="text" component={textInput} placeholder="Workspace Name" autoFocus/>
              </div>
              <div className="mb-2">
                <h4>Display Photo</h4>

                { newForm != "newForm" && initialData && initialData.logo &&
                  <img src={ initialData.logo.medium_url } width="100%" />
                }

                <Field
                  name="logo"
                  component={fileUpload}
                  type="file"
                  label="Upload Workspace Logo"
                />
              </div>

              { false && "TODO" && newForm == "newForm" &&
                <>
                  <h4 className="mt-4">Duplicate Workflow in This Workspace</h4>
                  <div className="mb-2">
                    <Field
                      name="duplicate_from_id"
                      component={selectField}
                      options={ initialData && initialData.data && initialData.data.filter(item => item.id !== workspace_id) }
                      label="Select Workspace"
                    />
                  </div>
                </>
              }
              <div className="form-buttons justify-content-start">
                <Button variant="primary" type="submit" disabled={ invalid || workspace_loading }>
                  <img src={CheckIcon} className="button-icon" />
                  { newForm == "newForm" ? 'Add' : 'Save' }
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

WorkSpaceForm =  reduxForm({
  form: 'work_spaceform',
  validate: validation,
  enableReinitialize: true
})(WorkSpaceForm);

const mapStateToProps = (state) => {
  const initialData = state.work_space.editWorkSpace;
  console.log(initialData);
  return {
    loading: state.work_space.loading,
    workspace_loading: state.work_space.workspace_loading,
    error: state.work_space.error,
    editWorkSpace: state.work_space.editWorkSpace,
    initialValues: {
      name: initialData && initialData.data && initialData.data.name,
      logo: initialData && initialData.data && initialData.data.logo
    }
  };
};

export default connect(mapStateToProps,{})(WorkSpaceForm);

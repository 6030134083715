export function getCardTagText(day_index, completed) {
  if(completed) return 'Completed'

  switch(day_index) {
    case 7:
    case 6:
    case 5:
    case 4:
    case 3:
      return `Due in ${day_index} days`;
    case 2:
      return 'Near Due';
    case 1:
      return 'Due Tomorrow';
    case 0:
      return 'Due Today'
    default:
      return 'Past Due'
  }
}
